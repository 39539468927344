import React from "react"
import { Link, Text } from 'theme-ui'

const Footer = () => (
    <Text
        p={20}
        sx={{
            fontSize: 'small',
            textAlign: 'center',
        }}
    >
        Internet by <Link href="https://kdsweb.de/" target="_blank" rel="noopener noreferrer">KDSweb</Link>
         &copy;{new Date().getFullYear()} | <Link href="/impressum" rel="noopener noreferrer">Impressum</Link> | <Link href="/datenschutz" rel="noopener noreferrer">Datenschutz</Link>
    </Text>
)

export default Footer;